import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { dispatch } from 'store';
import {
  IState,
  IResponse,
  IExam,
  IBuildingGroup,
  ICreateExam,
  ISubjectItem,
  IBuilding,
  ISubject,
  IAnswer,
  ISubjectItemEdit,
  IAnswerAppealComment,
  IAnswerAppealCommentBody,
  IUploadAnswer,
  IAnswerBody,
  IIndicatorsNewChecks,
  IXLSXFileInfo
} from 'types/exams';
import { IClient } from 'types/clients';
import { IUser } from 'types/users';
import { IChecklist } from 'types/checklists';
import { IError } from 'types';
import { v4 as uuid } from 'uuid';
import { CustomFile } from 'types/dropzone';
import { openSnackbar } from 'store/reducers/snackbar';

const initialState: IState = {
  exams: [],
  exam: null,
  clients: [],
  indicatorsNewChecks: {
    moderation: 0,
    verification: 0,
    appelation: 0
  },
  buildingGroups: [],
  auditors: [],
  checklists: [],
  answerAppealComments: [],
  loading: false,
  XLSXFileInfo: null,
  XLSXFileProgress: null,
  error: null,
  indexSubjectError: -1,
  indexBuildingError: [-1, -1],
  buildingByBuildingGroup: [],
  isEditAnswer: false
};

const examsSlice = createSlice({
  name: 'exams',
  initialState,
  reducers: {
    setExams(state: IState, action: PayloadAction<IExam[]>) {
      state.exams = action.payload;
    },

    setIndexSubjectError(state: IState, action: PayloadAction<number>) {
      state.indexSubjectError = action.payload;
    },

    setIndexBuildingError(state: IState, action: PayloadAction<number[]>) {
      state.indexBuildingError = action.payload;
    },

    setBuildingByBuildingGroup(state: IState, action: PayloadAction<IBuilding[]>) {
      state.buildingByBuildingGroup = action.payload;
    },

    setExam(state: IState, action: PayloadAction<IExam | null>) {
      state.exam = action.payload;
    },

    setLoading(state: IState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setXLSXFileInfo(state: IState, action: PayloadAction<IXLSXFileInfo | null>) {
      state.XLSXFileInfo = action.payload;
    },

    setXLSXFileProgress(state: IState, action: PayloadAction<number | null>) {
      state.XLSXFileProgress = action.payload;
    },

    setClients(state: IState, action: PayloadAction<IClient[]>) {
      state.clients = action.payload;
    },

    setBuildingGroups(state: IState, action: PayloadAction<IBuildingGroup[]>) {
      state.buildingGroups = action.payload;
    },

    setAuditors(state: IState, action: PayloadAction<IUser[]>) {
      state.auditors = action.payload;
    },

    setChecklists(state: IState, action: PayloadAction<IChecklist[]>) {
      state.checklists = action.payload;
    },

    addExam(state: IState, action: PayloadAction<IExam>) {
      state.exams = [...state.exams, action.payload];
    },

    setError(state: IState, action: PayloadAction<IError | null>) {
      state.error = action.payload;
    },

    setAnswerAppealComments(state: IState, action: PayloadAction<IAnswerAppealComment[]>) {
      state.answerAppealComments = action.payload;
    },

    setIsEditAnswer(state: IState) {
      state.isEditAnswer = !state.isEditAnswer;
    },

    setIndicatorsNewChecks(state: IState, action: PayloadAction<IIndicatorsNewChecks>) {
      state.indicatorsNewChecks = action.payload;
    }
  }
});

export default examsSlice.reducer;

export const { setError, setExam } = examsSlice.actions;

const configs = {
  headers: {
    'Content-Type': 'application/merge-patch+json'
  }
};

export function getExams() {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IExam[]>>(
        `${process.env.REACT_APP_API_URL}/exams?status[]=NEW&status[]=PROCESS&itemsPerPage=100`
      );
      dispatch(examsSlice.actions.setExams(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function getExam(id: string) {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IExam>(`${process.env.REACT_APP_API_URL}/exams/${id}`);
      dispatch(examsSlice.actions.setExam(response.data));
      return response.data;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function getBuildingsById(ids: string) {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IBuilding[]>>(`${process.env.REACT_APP_API_URL}/buildings?${ids}`);
      // dispatch(examsSlice.actions.setExams(response.data['hydra:member']));
      return response.data['hydra:member'];
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function getAnswersByQuestion(questionId: number, examId: number) {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      // const response = await axios.get<IResponse<IAnswer[]>>(`${process.env.REACT_APP_API_URL}/answers?question.id=${questionId}&exam.id=${examId}`);
      const response = await axios.get<IResponse<IAnswer[]>>(`${process.env.REACT_APP_API_URL}/answers?exam.id=${examId}`);
      return response.data['hydra:member'];
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function getExamsModeration() {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IExam[]>>(`${process.env.REACT_APP_API_URL}/exams?status=FINISH`);
      // const response = await axios.get<IResponse<IExam[]>>(`${process.env.REACT_APP_API_URL}/exams`);
      dispatch(examsSlice.actions.setExams(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function getExamsVerification() {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IExam[]>>(
        `${process.env.REACT_APP_API_URL}/exams?status[]=VERIFICATION&status[]=REVERIFICATION`
      );
      dispatch(examsSlice.actions.setExams(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function getExamsAppelation() {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IExam[]>>(`${process.env.REACT_APP_API_URL}/exams?status=APPEAL`);
      dispatch(examsSlice.actions.setExams(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function getExamsAccepted() {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IExam[]>>(`${process.env.REACT_APP_API_URL}/exams?status=ACCEPTED`);
      dispatch(examsSlice.actions.setExams(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function editExam(id: number, body: { moderator: string | null }) {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      await axios.patch<IExam>(`${process.env.REACT_APP_API_URL}/exams/${id}`, body, {
        headers: {
          'Content-Type': 'application/merge-patch+json'
        }
      });

      dispatch(examsSlice.actions.setIsEditAnswer());
    } catch (error) {
      dispatch(examsSlice.actions.setError(error as IError));
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function getClients() {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IClient[]>>(`${process.env.REACT_APP_API_URL}/clients`);
      dispatch(examsSlice.actions.setClients(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function getBuildingGroups() {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IBuildingGroup[]>>(`${process.env.REACT_APP_API_URL}/building_groups`);
      dispatch(examsSlice.actions.setBuildingGroups(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function getAuditors() {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IUser[]>>(`${process.env.REACT_APP_API_URL}/users?eRoles.code=AUDITOR`);
      dispatch(examsSlice.actions.setAuditors(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function createExam(body: ICreateExam, subjects: ISubjectItem[], navigation: (path: string) => void) {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      dispatch(examsSlice.actions.setIndexSubjectError(-1));
      dispatch(examsSlice.actions.setIndexBuildingError([-1, -1]));
      for (let i = 0; i < subjects.length; i++) {
        const subject = subjects[i];
        if (!subject.checklist) {
          dispatch(examsSlice.actions.setIndexSubjectError(i));
          return;
        }
        for (let j = 0; j < subject.buildings.length; j++) {
          const building = subject.buildings[j];
          if (!building.building) {
            dispatch(examsSlice.actions.setIndexBuildingError([i, j]));
            return;
          }
        }
      }

      const response = await axios.post<IExam>(`${process.env.REACT_APP_API_URL}/exams`, body);
      dispatch(examsSlice.actions.addExam(response.data));

      subjects.forEach(async (subject) => {
        const groupId = uuid();

        if (subject.buildings.length) {
          for (let i = 0; i < subject.buildings.length; i++) {
            const building = subject.buildings[i];

            await axios.post<ISubject>(`${process.env.REACT_APP_API_URL}/exam_subjects`, {
              exam: response.data['@id'],
              checklist: subject.checklist,
              buildingGroup: body.buildingGroup,
              building: building.building,
              entrance: building.entrance,
              groupId
            });
          }
        } else {
          await axios.post<ISubject>(`${process.env.REACT_APP_API_URL}/exam_subjects`, {
            exam: response.data['@id'],
            checklist: subject.checklist,
            buildingGroup: body.buildingGroup,
            entrance: subject.entrance,
            groupId
          });
        }
      });

      navigation('/exams');
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function editExamFull(id: string, body: ICreateExam, subjects: ISubjectItemEdit[], navigation: (path: string) => void) {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      dispatch(examsSlice.actions.setIndexSubjectError(-1));
      dispatch(examsSlice.actions.setIndexBuildingError([-1, -1]));
      for (let i = 0; i < subjects.length; i++) {
        const subject = subjects[i];
        if (!subject.checklist) {
          dispatch(examsSlice.actions.setIndexSubjectError(i));
          return;
        }
        for (let j = 0; j < subject.buildings.length; j++) {
          const building = subject.buildings[j];
          if (!building.building) {
            dispatch(examsSlice.actions.setIndexBuildingError([i, j]));
            return;
          }
        }
      }

      await axios.patch<IExam>(`${process.env.REACT_APP_API_URL}/exams/${id}`, body, configs);

      for (let i = 0; i < subjects.length; i++) {
        const subject = subjects[i];

        if (subject.status === 'edit') {
          for (let j = 0; j < subject.buildings.length; j++) {
            const building = subject.buildings[j];

            if (building.status === 'edit') {
              await axios.patch<ISubject>(
                `${process.env.REACT_APP_API_URL}/exam_subjects/${building.subjectId}`,
                {
                  building: building.building,
                  checklist: subject.checklist,
                  entrance: building.entrance
                },
                {
                  headers: {
                    'Content-Type': 'application/merge-patch+json'
                  }
                }
              );
            } else if (building.status === 'new') {
              await axios.post<ISubject>(`${process.env.REACT_APP_API_URL}/exam_subjects`, {
                exam: `/exams/${id}`,
                checklist: subject.checklist,
                buildingGroup: body.buildingGroup,
                building: building.building,
                entrance: building.entrance,
                groupId: subject.groupId
              });
            } else if (building.status === 'removed') {
              await axios.delete(`${process.env.REACT_APP_API_URL}/exam_subjects/${building.subjectId}`);
            }
          }
          if (!subject.buildings.length) {
            await axios.patch<ISubject>(
              `${process.env.REACT_APP_API_URL}/exam_subjects/${subject.id}`,
              {
                checklist: subject.checklist,
                entrance: subject.entrance
              },
              {
                headers: {
                  'Content-Type': 'application/merge-patch+json'
                }
              }
            );
          }
        } else if (subject.status === 'new') {
          const groupId = uuid();

          if (subject.buildings.length) {
            for (let i = 0; i < subject.buildings.length; i++) {
              const building = subject.buildings[i];

              await axios.post<ISubject>(`${process.env.REACT_APP_API_URL}/exam_subjects`, {
                exam: `/exams/${id}`,
                checklist: subject.checklist,
                buildingGroup: body.buildingGroup,
                building: building.building,
                entrance: building.entrance,
                groupId
              });
            }
          } else {
            await axios.post<ISubject>(`${process.env.REACT_APP_API_URL}/exam_subjects`, {
              exam: `/exams/${id}`,
              checklist: subject.checklist,
              buildingGroup: body.buildingGroup,
              entrance: subject.entrance,
              groupId
            });
          }
        } else if (subject.status === 'removed') {
          for (let j = 0; j < subject.buildings.length; j++) {
            const building = subject.buildings[j];
            await axios.delete(`${process.env.REACT_APP_API_URL}/exam_subjects/${building.subjectId}`);
          }
        }

        navigation('/exams');
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function getChecklists() {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IChecklist[]>>(`${process.env.REACT_APP_API_URL}/checklists`);
      dispatch(examsSlice.actions.setChecklists(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function getChecklistsByClient(clientId: number) {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IChecklist[]>>(`${process.env.REACT_APP_API_URL}/checklists?client.id=${clientId}`);
      dispatch(examsSlice.actions.setChecklists(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function getBuildingsByBuildingGroup(buildingGroupId: number) {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IBuilding[]>>(
        `${process.env.REACT_APP_API_URL}/buildings?buildingGroup.id=${buildingGroupId}`
      );
      dispatch(examsSlice.actions.setBuildingByBuildingGroup(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function editStatus(id: number, status: string, navigation: (path: string) => void) {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      await axios.patch<IResponse<IExam[]>>(
        `${process.env.REACT_APP_API_URL}/exams/${id}`,
        {
          status
        },
        {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        }
      );

      dispatch(examsSlice.actions.setIsEditAnswer());

      navigation('/verification');
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function addAnswerAppealComment(body: IAnswerAppealCommentBody, handleDrawerOpen: () => void) {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      await axios
        .post<IAnswerAppealComment>(`${process.env.REACT_APP_API_URL}/answer_appeal_comments`, body)
        .then((resp) => {
          dispatch(examsSlice.actions.setIsEditAnswer());
          handleDrawerOpen();
        })
        .catch((error) => {
          dispatch(
            openSnackbar({
              open: true,
              message: 'Что-то пошло не так :( Пожалуйста, повторите попытку',
              variant: 'alert',
              alert: {
                color: 'error'
              },
              close: false
            })
          );
        });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function editAnswerAppealComment(id: number, body: IAnswerAppealCommentBody, handleDrawerOpen: () => void) {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      await axios
        .patch<IAnswerAppealComment>(`${process.env.REACT_APP_API_URL}/answer_appeal_comments/${id}`, body, configs)
        .then((resp) => {
          dispatch(examsSlice.actions.setIsEditAnswer());
          handleDrawerOpen();
        })
        .catch((error) => {
          dispatch(
            openSnackbar({
              open: true,
              message: 'Что-то пошло не так :( Пожалуйста, повторите попытку',
              variant: 'alert',
              alert: {
                color: 'error'
              },
              close: false
            })
          );
        });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function getAnswerAppealComments(id: string) {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IAnswerAppealComment[]>>(
        `${process.env.REACT_APP_API_URL}/answer_appeal_comments?answer.exam.id=${id}`
      );
      dispatch(examsSlice.actions.setAnswerAppealComments(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function addAnswerUpload(images: CustomFile[], id: number) {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      for (let i = 0; i < images.length; i++) {
        const image = images[i];

        const bodyFormData = new FormData();
        bodyFormData.append('file', image);

        await axios<IUploadAnswer>({
          method: 'post',
          url: `${process.env.REACT_APP_API_URL}/answers/${id}/uploads`,
          data: bodyFormData
        });

        dispatch(examsSlice.actions.setIsEditAnswer());
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function removeAnswerUpload(imageId: string) {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      await axios
        .delete(`${process.env.REACT_APP_API_URL}${imageId}`)
        .then(() => dispatch(examsSlice.actions.setIsEditAnswer()))
        .catch(() =>
          dispatch(
            openSnackbar({
              open: true,
              message: 'Что-то пошло не так :( Пожалуйста, повторите попытку',
              variant: 'alert',
              alert: {
                color: 'error'
              },
              close: false
            })
          )
        );
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function addAnswer(body: IAnswerBody, files: CustomFile[]) {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      await axios
        .post<IAnswer>(`${process.env.REACT_APP_API_URL}/answers`, body)
        .then((res) => {
          dispatch(addAnswerUpload(files, +res.data['@id'].split('/')[res.data['@id'].split('/').length - 1]));
          dispatch(
            openSnackbar({
              open: true,
              message: 'Ответ успешно отправлен',
              variant: 'alert',
              alert: {
                color: 'success'
              },
              close: false
            })
          );
          dispatch(examsSlice.actions.setIsEditAnswer());
        })
        .catch((e) => {
          dispatch(
            openSnackbar({
              open: true,
              message: 'Что-то пошло не так :( Пожалуйста, повторите попытку',
              variant: 'alert',
              alert: {
                color: 'error'
              },
              close: false
            })
          );
        });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function editAnswer(id: number, body: IAnswerBody) {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      await axios
        .patch<IAnswer>(`${process.env.REACT_APP_API_URL}/answers/${id}`, body, configs)
        .then((res) => {
          dispatch(examsSlice.actions.setIsEditAnswer());
        })
        .catch((e) => {
          dispatch(
            openSnackbar({
              open: true,
              message: 'Что-то пошло не так :( Пожалуйста, повторите попытку',
              variant: 'alert',
              alert: {
                color: 'error'
              },
              close: false
            })
          );
        });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}

export function getXLSXFile(id: number) {
  return async () => {
    dispatch(
      examsSlice.actions.setXLSXFileInfo({
        title: id + '.xlsx',
        isLoading: true
      })
    );
    try {
      // const response = await axios.get(`${process.env.REACT_APP_API_URL}/exams/${id}/file/xlsx`, { responseType: 'blob' });
      const response = await axios.request({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/exams/${id}/file/xlsx`,
        responseType: 'blob',
        onDownloadProgress: (progress) => {
          let percentCompleted = Math.round((progress.loaded * 100) / progress.total!);
          dispatch(examsSlice.actions.setXLSXFileProgress(percentCompleted));
        }
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${id}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        dispatch(examsSlice.actions.setXLSXFileInfo(null));
        dispatch(examsSlice.actions.setXLSXFileProgress(null));
      }, 2000);
    }
  };
}

export function getCountExams() {
  return async () => {
    dispatch(examsSlice.actions.setLoading(true));
    try {
      const moderationExams = await axios.get<IResponse<IExam[]>>(`${process.env.REACT_APP_API_URL}/exams?status=FINISH`);
      const verificationExams = await axios.get<IResponse<IExam[]>>(
        `${process.env.REACT_APP_API_URL}/exams?status[]=VERIFICATION&status[]=REVERIFICATION`
      );
      const appelationExams = await axios.get<IResponse<IExam[]>>(`${process.env.REACT_APP_API_URL}/exams?status=APPEAL`);

      dispatch(
        examsSlice.actions.setIndicatorsNewChecks({
          moderation: moderationExams.data['hydra:totalItems'],
          verification: verificationExams.data['hydra:totalItems'],
          appelation: appelationExams.data['hydra:totalItems']
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(examsSlice.actions.setLoading(false));
    }
  };
}
