import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { IState, IResponse, IUser, IRole, INewUser, IEditUser, IFileData } from 'types/users';

const initialState: IState = {
  users: [],
  user: null,
  roles: [],
  loading: false
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    allUsers(state, action: PayloadAction<IUser[]>) {
      state.users = action.payload;
    },

    oneUser(state, action: PayloadAction<IUser | null>) {
      state.user = action.payload;
    },

    newUser(state, action: PayloadAction<IUser>) {
      state.users = [...state.users, action.payload];
    },

    editUser(state, action: PayloadAction<IUser>) {
      state.users = [...state.users.filter((item) => item.id !== action.payload.id), action.payload];
    },

    allRoles(state, action: PayloadAction<IRole[]>) {
      state.roles = action.payload;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    removeUser(state, action: PayloadAction<number>) {
      state.users = state.users.filter((item) => item.id !== action.payload);
    }
  }
});

export default usersSlice.reducer;

export const { oneUser } = usersSlice.actions;

export function getUsers() {
  return async () => {
    dispatch(usersSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IUser[]>>(`${process.env.REACT_APP_API_URL}/users`);
      dispatch(usersSlice.actions.allUsers(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(usersSlice.actions.setLoading(false));
    }
  };
}

export function getUser(id: number) {
  return async () => {
    dispatch(usersSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IUser>(`${process.env.REACT_APP_API_URL}/users/${id}`);
      dispatch(usersSlice.actions.oneUser(response.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(usersSlice.actions.setLoading(false));
    }
  };
}

export function getAllRoles() {
  return async () => {
    dispatch(usersSlice.actions.setLoading(true));
    try {
      const response = await axios.get<IResponse<IRole[]>>(`${process.env.REACT_APP_API_URL}/roles`);
      dispatch(usersSlice.actions.allRoles(response.data['hydra:member']));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(usersSlice.actions.setLoading(false));
    }
  };
}

export function createUser(body: INewUser, avatar: File | undefined) {
  return async () => {
    dispatch(usersSlice.actions.setLoading(true));
    try {
      if (!avatar) {
        const responseUser = await axios.post<IUser>(`${process.env.REACT_APP_API_URL}/users`, body);
        dispatch(usersSlice.actions.newUser(responseUser.data));
        return;
      }

      const bodyFormData = new FormData();
      bodyFormData.append('file', avatar);

      const responseAvatar = await axios<IFileData>({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/avatars`,
        data: bodyFormData
      });

      const responseUser = await axios.post<IUser>(`${process.env.REACT_APP_API_URL}/users`, {
        ...body,
        avatar: responseAvatar.data['@id']
      });
      dispatch(usersSlice.actions.newUser(responseUser.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(usersSlice.actions.setLoading(false));
    }
  };
}

export function editUser(body: IEditUser, id: number, avatar: File | undefined, newPassword?: string) {
  return async () => {
    dispatch(usersSlice.actions.setLoading(true));
    try {
      if (!avatar) {
        const response = await axios.patch<IUser>(
          `${process.env.REACT_APP_API_URL}/users/${id}`,
          newPassword ? { ...body, plainPassword: newPassword } : body,
          {
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          }
        );
        dispatch(usersSlice.actions.editUser(response.data));
        return;
      }

      const bodyFormData = new FormData();
      bodyFormData.append('file', avatar);

      const responseAvatar = await axios<IFileData>({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/avatars`,
        data: bodyFormData
      });

      const response = await axios.patch<IUser>(
        `${process.env.REACT_APP_API_URL}/users/${id}`,
        newPassword
          ? { ...body, plainPassword: newPassword, avatar: responseAvatar.data['@id'] }
          : { ...body, avatar: responseAvatar.data['@id'] },
        {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        }
      );
      dispatch(usersSlice.actions.editUser(response.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(usersSlice.actions.setLoading(false));
    }
  };
}

export function deleteUser(id: number) {
  return async () => {
    dispatch(usersSlice.actions.setLoading(true));
    try {
      await axios
        .delete(`${process.env.REACT_APP_API_URL}/users/${id}`)
        .then(() => {
          dispatch(usersSlice.actions.removeUser(id));
        })
        .catch((error) => {
          dispatch(
            openSnackbar({
              open: true,
              message: error['hydra:description'],
              variant: 'alert',
              alert: {
                color: 'error'
              },
              close: false
            })
          );
        });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(usersSlice.actions.setLoading(false));
    }
  };
}
