import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { dispatch } from 'store';
import { BuildingDto, BuildingProps } from 'types/building';
import { randomIntFromInterval } from 'utils/randomIntFromInterval';
import { openSnackbar } from './snackbar';

const handleError = (error: any) => {
  dispatch(
    openSnackbar({
      open: true,
      message: error['hydra:description'],
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: false
    })
  );
};

const initialState: BuildingProps = {
  buildings: [],
  error: false,
  isLoader: false,
  isModalOpen: false,
  rerender: 0
};

const buildingsSlice = createSlice({
  name: 'buildings',
  initialState,
  reducers: {
    // loader
    loading(state) {
      state.isLoader = true;
    },

    // error
    hasError(state, action) {
      state.isLoader = false;
      state.error = action.payload;
    },

    // BuildingGroups
    setBuildingGroups(state, action) {
      state.isLoader = false;
      state.buildings = action.payload;
    },

    // // create event
    createEvent(state) {
      state.isLoader = false;
      state.isModalOpen = false;
    },

    // delete BuildingGroup
    deleteBuildingGroup(state, action) {
      const { eventId } = action.payload;
      state.isModalOpen = false;
      state.buildings = state.buildings.filter((b) => b.id !== eventId);
    },

    rerender(state) {
      state.rerender = randomIntFromInterval();
    }
  }
});

export default buildingsSlice.reducer;

export const { setBuildingGroups } = buildingsSlice.actions;
const token = `Bearer ${localStorage.getItem('serviceToken')}`;

const headers = { Authorization: token, 'content-type': 'application/ld+json; charset=utf-8' };

export function getBuildings(id: string) {
  return async () => {
    dispatch(buildingsSlice.actions.loading());
    try {
      //TODO
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/building_groups/${id}/buildings`);

      dispatch(buildingsSlice.actions.setBuildingGroups(response.data['hydra:member']));
    } catch (error) {
      dispatch(buildingsSlice.actions.hasError(error));
    }
  };
}

export function createBuilding(id: string, newEvent: { title: string; buildingGroup: string; entrances: number | null }) {
  return async () => {
    dispatch(buildingsSlice.actions.loading());
    try {
      //TODO
      await axios.post(`${process.env.REACT_APP_API_URL}/buildings`, newEvent, { headers });

      dispatch(buildingsSlice.actions.hasError(false));
      dispatch(buildingsSlice.actions.rerender());
    } catch (error) {
      dispatch(buildingsSlice.actions.hasError(error));
    }
  };
}

export function updateBuilding(id: number, newEvent: BuildingDto) {
  return async () => {
    dispatch(buildingsSlice.actions.loading());
    try {
      //TODO
      await axios.patch(`${process.env.REACT_APP_API_URL}/buildings/${id}`, newEvent, { headers }).catch(handleError);

      dispatch(buildingsSlice.actions.rerender());
    } catch (error) {
      dispatch(buildingsSlice.actions.hasError(error));
    }
  };
}

export function deleteBuilding(id: string) {
  return async () => {
    dispatch(buildingsSlice.actions.loading());
    dispatch(buildingsSlice.actions.hasError(false));
    try {
      //TODO
      //@ts-ignore
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/buildings/${id}`, { headers });
      //@ts-ignore
      if (response['hydra:description']) {
        return dispatch(buildingsSlice.actions.hasError(true));
      }

      dispatch(buildingsSlice.actions.deleteBuildingGroup({ id }));
      dispatch(buildingsSlice.actions.rerender());
    } catch (error) {
      dispatch(buildingsSlice.actions.hasError(error));
    }
  };
}
