import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { dispatch, useSelector } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { getBuildingsGroups } from 'store/reducers/buildingGroupsSlice';
import { BuildingGroup } from 'types/buildingGroup';
import { Building } from 'types/building';
import { updateBuilding } from 'store/reducers/buildingsSlice';

export interface Props {
  building: Pick<Building, 'title' | 'id' | 'entrances'>;
  onCancel: () => void;
}

const EditBuilding = ({ building, onCancel }: Props) => {
  const { id } = useParams();
  const { buildingGroups } = useSelector((state) => state.buildingsGroupsSlice);

  const getInitialValues = () => {
    const newCustomer = {
      title: building.title,
      buildingGroup: id ? '/building_groups/' + id : '',
      entrances: building.entrances
    };

    return newCustomer;
  };

  useEffect(() => {
    dispatch(getBuildingsGroups());
  }, []);

  const CustomerSchema = Yup.object().shape({
    title: Yup.string().max(255).required('Обязательное поле'),
    buildingGroup: Yup.string().max(255).required('Обязательное поле'),
    entrances: Yup.number().required('Обязательное поле')
  });

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: CustomerSchema,
    onSubmit: (_, { setSubmitting }) => {
      try {
        const { title, buildingGroup, entrances } = formik.values;

        dispatch(updateBuilding(Number(building.id), { title, buildingGroup, entrances }));
        !errors &&
          dispatch(
            openSnackbar({
              open: true,
              message: 'Адрес успешно обновлен.',
              variant: 'alert',
              alert: {
                color: 'success'
              },
              close: false
            })
          );
        setSubmitting(false);
        onCancel();
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { errors, touched, isSubmitting, getFieldProps, setFieldValue, handleSubmit } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>Редактировать адрес</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="title">Адрес</InputLabel>
                    <TextField
                      fullWidth
                      id="title"
                      placeholder="Укажите адрес"
                      {...getFieldProps('title')}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="buildingGroup">Роль</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        id="buildingGroup"
                        displayEmpty
                        {...getFieldProps('buildingGroup')}
                        onChange={(event: SelectChangeEvent<string>) => setFieldValue('buildingGroup', event.target.value as string)}
                        input={<OutlinedInput id="select-column-hiding" placeholder="Sort by" />}
                        renderValue={(selected) => {
                          if (!selected) {
                            return <Typography variant="subtitle1">Укажите адрес ЖК</Typography>;
                          }

                          return (
                            <Typography variant="subtitle2">
                              {buildingGroups.find((buildingGroup) => buildingGroup['@id'] === selected)?.title}
                            </Typography>
                          );
                        }}
                      >
                        {buildingGroups.map((buildingGroup: BuildingGroup) => (
                          <MenuItem key={buildingGroup['@id']} value={buildingGroup['@id']}>
                            <ListItemText primary={buildingGroup.title} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {touched.buildingGroup && errors.buildingGroup && (
                      <FormHelperText error id="buildingGroup" sx={{ pl: 1.75 }}>
                        {errors.buildingGroup}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="entrances">Количество подъездов</InputLabel>
                    <TextField
                      fullWidth
                      id="entrances"
                      type="number"
                      placeholder="Укажите количество подъездов"
                      {...getFieldProps('entrances')}
                      error={Boolean(touched.entrances && errors.entrances)}
                      helperText={touched.entrances && errors.entrances}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="flex-end" alignItems="center">
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={onCancel}>
                      Отмена
                    </Button>
                    <Button type="submit" variant="contained" disabled={isSubmitting}>
                      Редактировать
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default EditBuilding;
