import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import BuildingsPage from 'pages/apps/buildings/list';

const AppKanbanBacklogs = Loadable(lazy(() => import('sections/apps/kanban/Backlogs')));
const ChecklistsEditPage = Loadable(lazy(() => import('sections/apps/kanban/Backlogs/edit-page')));

const UsersList = Loadable(lazy(() => import('pages/users/')));
const ClientList = Loadable(lazy(() => import('pages/clients/')));
//TODO
const BuildingGroupsList = Loadable(lazy(() => import('pages/buildingGroups/')));
const ChecklistsList = Loadable(lazy(() => import('pages/checklists/')));
const VerificationsList = Loadable(lazy(() => import('pages/apps/verification')));
const AppelationsList = Loadable(lazy(() => import('pages/apps/appelation')));
const AcceptedExamList = Loadable(lazy(() => import('pages/apps/accepted')));

// pages routing
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));
const ExamTable = Loadable(lazy(() => import('pages/apps/exams')));
const ModerationTable = Loadable(lazy(() => import('pages/apps/moderation')));
const AddExam = Loadable(lazy(() => import('pages/apps/exams/create')));
const EditExam = Loadable(lazy(() => import('pages/apps/exams/edit')));
const ViewExam = Loadable(lazy(() => import('pages/apps/exams/view')));
const DetailedEditPage = Loadable(lazy(() => import('pages/apps/moderation/detailed')));
const DetailedViewPage = Loadable(lazy(() => import('sections/apps/exams/detailed-view')));

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'users',
          element: <UsersList />
        },
        {
          path: 'clients',
          element: <ClientList />
        },
        {
          path: 'buildingGroups',
          element: <BuildingGroupsList />
        },
        {
          path: 'building/:id',
          element: <BuildingsPage />
        },
        {
          path: 'checklists',
          element: <ChecklistsList />
        },
        {
          path: 'checklists/create',
          element: <AppKanbanBacklogs />
        },
        {
          path: 'checklists/edit/:id',
          element: <ChecklistsEditPage />
        },
        {
          path: 'exams',
          element: <ExamTable />
        },
        {
          path: 'moderation',
          element: <ModerationTable />
        },
        {
          path: 'verification',
          element: <VerificationsList />
        },
        {
          path: 'appelation',
          element: <AppelationsList />
        },
        {
          path: 'accepted',
          element: <AcceptedExamList />
        },
        {
          path: 'view/accepted/:id',
          element: <DetailedViewPage />
        },
        {
          path: 'view/appelation/:id',
          element: <DetailedViewPage />
        },
        {
          path: 'view/verification/:id',
          element: <DetailedViewPage />
        },
        {
          path: 'view/moderation/:id',
          element: <DetailedViewPage />
        },
        {
          path: 'exams/create',
          element: <AddExam />
        },
        {
          path: 'exams/edit/:id',
          element: <EditExam />
        },
        {
          path: 'exams/view/:id',
          element: <ViewExam />
        },
        {
          path: 'moderation/:id',
          element: <DetailedEditPage />
        },
        {
          path: 'verification/:id',
          element: <DetailedEditPage />
        },
        {
          path: 'appelation/:id',
          element: <DetailedEditPage />
        },
        {
          path: 'exams/:id',
          element: <DetailedEditPage />
        }
      ]
    },
    {
      path: '/auth',
      element: <CommonLayout />,
      children: [
        {
          path: 'login',
          element: <AuthLogin />
        },
        {
          path: 'register',
          element: <AuthRegister />
        },
        {
          path: 'forgot-password',
          element: <AuthForgotPassword />
        },
        {
          path: 'reset-password',
          element: <AuthResetPassword />
        },
        {
          path: 'check-mail',
          element: <AuthCheckMail />
        },
        {
          path: 'code-verification',
          element: <AuthCodeVerification />
        }
      ]
    }
  ]
};

export default MainRoutes;
